export const useReplaceInput = () => {
	/* white list */
	const stringReg = /[^\u0E00-\u0E7Fa-zA-Z\s]/g
	const stringNumberReg = /[^\u0E00-\u0E7Fa-zA-Z0-9\s]/g
	const numberReg = /[^0-9]/g
	const decimalReg = /[^0-9.]|\.(?=.*\.)/g
	const emojiWlReg = /[^\uD800-\uDBFF\u2702-\u27B0\uF680-\uF6C0\u24C2-\uF251\s]/g
	const stringSpecialReg = /[^\u0E00-\u0E7Fa-zA-Z0-9-!@#$%^&*()_+|~=`{}[\]:";'<>?,\s“./\\s]/g
	/* white list */
	/* back list */
	const emojiBlReg = /[\uD800-\uDBFF\u2702-\u27B0\uF680-\uF6C0\u24C2-\uF251\s]/g
	/* back list */

	/* white list */
	const string = (value: string): string => {
		return value.replace(stringReg, '')
	}
	const stringNumber = (value: string) => {
		return value.replace(stringNumberReg, '')
	}
	const number = (value: string): string => {
		return value.replace(numberReg, '')
	}
	const decimal = (value: string): string => {
		return value.replace(decimalReg, '')
	}
	const emojiWl = (value: string): string => {
		return value.replace(emojiWlReg, '')
	}
	const stringSpecial = (value: string): string => {
		return value.replace(stringSpecialReg, '')
	}
	/* white list */

	/* back list */
	const emojiBl = (value: string): string => {
		return value.replace(emojiBlReg, '')
	}
	/* back list */

	const paymentFieldConvert = (value: string): string => {
		const parseValue = value.replace(decimalReg, '')
		let newValue = parseFloat(parseValue)
		const isVaule = newValue === null || isNaN(newValue)
		if (isVaule) {
			newValue = 0
		}
		return newValue.toFixed(2)
	}

	const decimalValueChecker = (event: any) => {
		const isAllow: boolean = /[0-9.]/.test(String.fromCharCode(event.which))
		if (!isAllow) {
			event.preventDefault()
		}
	}

	const decimalValueCheckerNotIncludeDot = (event: any) => {
		const isAllow: boolean = /[0-9]/.test(String.fromCharCode(event.which))
		if (!isAllow) {
			event.preventDefault()
		}
	}


	const decimalNumberValueChecker = (event: any) => {
		const isAllow: boolean = /[0-9]/.test(String.fromCharCode(event.which))
		if (!isAllow) {
			event.preventDefault()
		}
	}

	const onPasteTextNumber = (event: any) => {
		event.preventDefault()
		let input = event.clipboardData.getData('text')
		input = input.replace(/[^0-9.]/g, '')
		return input
	}

	const onPasteNumber = (event: any) => {
		event.preventDefault()
		let input = event.clipboardData.getData('text')
		input = input.replace(/[^0-9]/g, '')
		return input
	}

	const validatePhone = (rule: any, value: string, callback: any) => {
		if (value) {
			const isAllow: boolean = /^((02|03|04|05|06|07|08|09))/gm.test(value)
			if (!isAllow) {
				callback(new Error('กรุณากรอกเบอร์โทรศัพท์ ขึ้นต้นด้วย (02), (03), (04), (05), (06), (07), (08), (09)'))
				return
			}

			const isAllow9Char: boolean = /^((02|03|04|05|07))/gm.test(value)
			if (isAllow9Char) {
				if (value.length !== 9) {
					callback(new Error('กรุณากรอกเบอร์โทรศัพท์ 9 ตัวอักษร'))
					return
				}
			}

			const isAllow10Char: boolean = /^((06|08|09))/gm.test(value)
			if (isAllow10Char) {
				if (value.length !== 10) {
					callback(new Error('กรุณากรอกเบอร์โทรศัพท์ 10 ตัวอักษร'))
					return
				}
			}

			callback()
			return
		} else {
			callback()
			return
		}
	}

	return {
		string,
		stringNumber,
		number,
		decimal,
		emojiWl,
		emojiBl,
		paymentFieldConvert,
		decimalValueChecker,
		stringSpecial,
		onPasteTextNumber,
		decimalNumberValueChecker,
		onPasteNumber,
		validatePhone,
		decimalValueCheckerNotIncludeDot,
	}
}